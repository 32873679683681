import React, { useState } from "react";
import styled from "styled-components";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Container } from "@mui/material";

const Landing = () => {
  const [iptvalue, setIptvalue] = useState(0);
  return (
    <Layout>
      <WrapperBx className="herosec">
        <div className="topnav">
          <div className="marquee-container">
            <div className="marquee-content">
              no team allocation - no sales tax - contract revoked - no team
              allocation - no sales tax
            </div>
          </div>
          <div className="logocener">
            <div className="mkt">marketcap: $250,000,000</div>
            <img src="images/icon-trans.png" alt="" />
          </div>
        </div>
        <img className="heroimg" src="images/signage 1.png" alt="" />
      </WrapperBx>
      <Container maxWidth="md">
        <WrapperBx className="newssec">
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <div className="bxwrapper">
                <h2>Latest News</h2>
                <div className="rwbx">
                  <div className="imgwrapper">
                    {" "}
                    <img src="images/dollarsigngr.png" alt="" />
                  </div>
                  <a
                    target="_blank"
                    href="https://explorer.solana.com/address/799gbQCg6c1VHrN4YeWda76A4SE4F2BtQgcov8Eu95xK"
                  >
                    Contract Address
                  </a>
                </div>
                <div className="rwbx">
                  <div className="imgwrapper">
                    {" "}
                    <img src="images/dollarsignbling.png" alt="" />
                  </div>
                  <a
                    target="_blank"
                    href="https://raydium.io/swap/?inputCurrency=sol&inputSymbol=USD&outputCurrency=799gbQCg6c1VHrN4YeWda76A4SE4F2BtQgcov8Eu95xK&fixed=in"
                  >
                    Buy $USD
                  </a>
                </div>
                <div className="rwbx">
                  <div className="imgwrapper">
                    {" "}
                    <img src="images/dollarsign.png" alt="" />
                  </div>
                  <a target="_blank" href="https://twitter.com/dollartreeio">
                    Twitter
                  </a>
                </div>
                <div className="rwbx">
                  <div className="imgwrapper">
                    {" "}
                    <img src="images/dollarsign3.png" alt="" />
                  </div>
                  <a
                    target="_blank"
                    href="https://www.dextools.io/app/en/solana/pair-explorer/51oz3FRUjpjQVuwtrcAKNXzh1PHJMpMJmZCVaamoyo84"
                  >
                    DexTools
                  </a>
                </div>
                <div className="rwbx">
                  <div className="imgwrapper">
                    {" "}
                    <img src="images/telegram.png" alt="" />
                  </div>
                  <a target="_blank" href="https://t.me/dollartreeio">
                    Telegram
                  </a>
                </div>
              </div>
            </Grid>
            <Grid item sm={6} xs={12}>
              <div className="bxwrapper">
                <img src="images/chad 1.png" className="chadimage" alt="" />
                <img
                  src="images/chad-eotm 1.png"
                  className="monthemployee"
                  alt=""
                />
                congratulations Chad our employee of the month
              </div>
            </Grid>
          </Grid>
        </WrapperBx>
        <WrapperBx className="infosec">
          <h2 className="heading">
            This is a Solana Project
            <img src="images/solana2 1.png" alt="" />
          </h2>
          <Grid
            container
            spacing={2}
            alignItems="center"
            direction={{ xs: "column-reverse", sm: "row" }}
          >
            <Grid item sm={5} xs={12}>
              <div className="bxwrapper">
                <img src="images/dtreefam 1.png" className="infoimg" alt="" />
              </div>
            </Grid>
            <Grid item sm={7} xs={12}>
              <div className="bxwrapper text">
                Dollar Tree ($USD) is a no-nonsense memecoin aka future 1$
                stablecoin unleashed on Solana. Unlike the endlessly printed,
                inflation-feeding regular USD, our $USD stands as the true
                'people's USD'. It's stripped of complications: no contract
                tricks, 0% sales tax, and no team handouts. $USD the people's
                USD on the people's blockchain
              </div>
            </Grid>
          </Grid>
          <div className="buybtn">
            Buy $USD{`-->`}
            <button>
              <a
                target="_blank"
                href="https://raydium.io/swap/?inputCurrency=sol&inputSymbol=USD&outputCurrency=799gbQCg6c1VHrN4YeWda76A4SE4F2BtQgcov8Eu95xK&fixed=in"
              >
                {" "}
                <img src="images/buynow 1.png" className="btn" alt="" />
              </a>
            </button>
          </div>
        </WrapperBx>
        <WrapperBx className="sellsec">
          <h2 className="heading">
            <img src="images/wegotdrip 1.png" alt="" />
          </h2>
          <Grid container spacing={2} alignItems="center">
            <Grid item sm={4} xs={12}>
              <div className="sellcard">
                <a target="_blank" href="https://www.dollartreegear.com/en/usd">
                  <img src="images/3pak.png" alt="" />
                  Buy Now
                </a>
              </div>
            </Grid>
            <Grid item sm={4} xs={12}>
              <div className="sellcard">
                <a target="_blank" href="https://www.dollartreegear.com/en/usd">
                  <img src="images/cart.png" alt="" />
                  Buy Now
                </a>
              </div>
            </Grid>
            <Grid item sm={4} xs={12}>
              <div className="sellcard">
                <a target="_blank" href="https://www.dollartreegear.com/en/usd">
                  <img src="images/waterbottle.png" alt="" />
                  Buy Now
                </a>
              </div>
            </Grid>
          </Grid>
        </WrapperBx>
      </Container>
      <Footer>
        <Container maxWidth="md">
          <div className="footer">
            <img
              src="images/icon-whitecircle.png"
              className="footerimg"
              alt=""
            />
            Copyright 2023 all rights reserved
          </div>
        </Container>
      </Footer>
    </Layout>
  );
};
const Footer = styled.div`
  background: #000;
  margin-top: 80px;
  padding: 30px 0;
  text-align: Center;

  .MuiContainer-root {
    position: Relative;
  }
  .footer {
    font-size: 24px;
    text-align: center;
    .footerimg {
      height: 46px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      @media screen and (max-width: 600px) {
        position: Relative;
        transform: none;
      }
    }

    @media screen and (max-width: 600px) {
      display: flex;
      flex-direction: column;
      font-size: 20px;
      .footerimg {
        width: auto;
        object-fit: contain;
        margin-bottom: 30px;
      }
    }
  }
`;
const WrapperBx = styled.div`
  &.herosec {
    width: 100%;
    .heroimg {
      width: 100%;
    }
    .topnav {
      position: absolute;
      top: 0;
      width: 100%;
      left: 0;
      .logocener {
        position: Relative;
        display: block;
        .mkt {
          color: #000;
          font-family: Times;
          font-size: 20px;
          font-weight: 700;
          position: absolute;
          right: 20px;
          top: 10px;
          filter: drop-shadow(3px 3px 0px #fcfe01);
        }
        img {
          height: 100px;
          display: block;
          margin: auto;
        }
      }
      @media screen and (max-width: 600px) {
        position: relative;
        background: #fff;
        display: flex;
        flex-direction: column;
        .logocener {
          .mkt {
            position: relative;
            text-align: center;
            margin-bottom: 30px;
            top: 20px;
            right: 0;
          }
          img {
            height: 110px;
            margin-bottom: 25px;
          }
        }
      }
    }
  }
  &.newssec {
    margin-top: 70px;
    h2 {
      color: #000;
      font-family: "MatrixIIOT-Reg";
      font-size: 50px;
      margin-bottom: 30px;
      font-weight: 400;
    }
    .bxwrapper {
      position: Relative;
      .rwbx {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        font-size: 36px;
        font-family: "MatrixIIOT-Reg";
        &:last-child {
          margin-bottom: 0;
        }
        .imgwrapper {
          width: 50px;
          display: flex;
          align-items: center;
        }
        img {
          // min-width: 50px;
          width: auto;
          height: 46px;
          margin-right: 20px;
        }
      }
      .chadimage {
        width: 100%;
      }
      .monthemployee {
        position: absolute;
        width: 45%;
        max-width: 350px;
        right: 30px;
        z-index: 99;
        bottom: 90px;
      }
    }
  }
  &.infosec {
    margin-top: 70px;
    .heading {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000;
      text-align: center;
      font-size: 40px;
      img {
        height: 80px;
        margin-left: 40px;
      }
      @media screen and (max-width: 600px) {
        flex-direction: column;
        font-size: 32px;
        img {
          height: auto;
          width: 100%;
          max-width: 100px;
          margin-left: 0px;
          margin-top: 24px;
        }
      }
    }
    .bxwrapper {
      color: #fcfe01;
      font-size: 30px;
      .infoimg {
        width: 100%;
        height: auto;
      }
    }
    .buybtn {
      color: #0107ff;
      font-size: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 50px;
      font-family: "MatrixIIOT-Reg";
      button {
        border: 0;
        background: 0;
        img {
          height: 48px;
          width: auto;
          margin-left: 20px;
        }
      }
      @media screen and (max-width: 500px) {
        flex-direction: column;
        button {
          img {
            margin: 0;
            margin-top: 20px;
          }
        }
      }
    }
  }
  &.sellsec {
    margin-top: 60px;
    .heading {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 24px;
      img {
        height: 80px;
      }
    }
    .sellcard {
      text-align: center;
      font-size: 36px;
      margin-bottom: 30px;
      img {
        width: 100%;
        margin-bottom: 12px;
      }
    }
  }
`;
const Layout = styled.div`
  @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  /* Marquee Container Styles */
  .marquee-container {
    width: 100%;
    overflow: hidden;
    padding: 10px 0;
    background: linear-gradient(
      45deg,
      #ff0000,
      #ff9900,
      #ffff00,
      #33cc33,
      #3399ff,
      #cc66ff,
      #ff0099
    );
  }

  /* Marquee Content Styles */
  .marquee-content {
    white-space: nowrap;
    font-size: 20px;
    font-weight: 600;
    animation: marquee 16s linear infinite; /* Adjust the animation duration as needed */
  }
`;

export default Landing;
